import { removeElementById } from 'utils/browser';
import { initStorytapSnippet } from './storytapSnippet';

class StorytapService {
  private loaded = false;

  private setLoaded = () => {
    this.loaded = true;
  };

  public init = ({ eid, id, lang }) => {
    initStorytapSnippet(this.setLoaded);

    const defaultOptions = {
      eid,
      id,
      lang,
    };

    (window as any).stv('init', { key: process.env.GATSBY_STORYTAP_KEY, debug: false });
    (window as any).stv('show', {
      ...defaultOptions,
    });
  };

  public clearQueue = () => {
    if (!this.loaded) {
      return;
    }
    (window as any).stv.q = [];
  };

  public removeScript = () => {
    removeElementById('stv');
    (window as any).stv = null;
  };
}

export default new StorytapService();
