import React, { FC } from 'react';

import classNames from 'classnames';
import PlainImage from 'common/Image/PlainImage';
import Image from 'gatsby-theme-husky/src/common/Image';
import { ProductImageGalleryProps } from './models';

import './ProductImageGallery.scss';

const ProductImageGallery: FC<ProductImageGalleryProps> = ({
  productTitle,
  imagesArray,
  mainImage,
  setMainImage,
  setMainLocalImage,
}) => {
  const changeMainImages = (image, localImage) => {
    setMainImage(image);
    setMainLocalImage(localImage);
  };

  return (
    <div className="product-gallery">
      <div className="product-gallery__list">
        {imagesArray.map((productImageUrls, index) => (
          <button
            type="button"
            key={productImageUrls.image}
            className={classNames('product-gallery__item', {
              'active--item': mainImage === productImageUrls.image,
            })}
            onClick={() => changeMainImages(productImageUrls.image, productImageUrls.localImage)}
          >
            {productImageUrls.localImage ? (
              <Image localImage={productImageUrls.localImage} alt={`${productTitle} ${index}`} />
            ) : productImageUrls.image ? (
              <PlainImage lazyLoad src={productImageUrls.image} alt={`${productTitle} ${index}`} />
            ) : null}
          </button>
        ))}
      </div>
    </div>
  );
};

export default ProductImageGallery;
