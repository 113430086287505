import React, { FC } from 'react';
import UmbracoImage from 'common/Image/UmbracoImage';
import { ApplicationAreasItemType } from '../models';

import './ApplicationAreasItem.scss';

const ApplicationAreasItem: FC<ApplicationAreasItemType> = ({
  applicationAreasItemImage,
  applicationAreasItemTitle,
}) => (
  <div className="application-area">
    <div className="application-area__image">
      {applicationAreasItemImage ? (
        <UmbracoImage image={applicationAreasItemImage} alt={applicationAreasItemTitle} />
      ) : null}
    </div>
    {applicationAreasItemTitle ? (
      <h3 className="application-area__title">{applicationAreasItemTitle}</h3>
    ) : null}
  </div>
);

export default ApplicationAreasItem;
