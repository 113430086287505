import React, { FC } from 'react';
import UmbracoImage from 'common/Image/UmbracoImage';
import { ApplyListItemType } from '../models';

import './HowApplyStep.scss';

const HowApplyStep: FC<ApplyListItemType> = ({ applyItemImage, applyItemTitle }) => (
  <li className="how-apply-step">
    {applyItemImage ? (
      <div className="how-apply-step__image">
        <UmbracoImage image={applyItemImage} alt={applyItemTitle} />
      </div>
    ) : null}
    {applyItemTitle ? <h3 className="how-apply-step__title">{applyItemTitle}</h3> : null}
  </li>
);

export default HowApplyStep;
