/* eslint-disable */
// @ts-nocheck
export const initStorytapSnippet = (cb) => {
  (function (w, d, s, o, f, js, fjs) {
    w['storytap-videos'] = o;
    w[o] =
      w[o] ||
      function () {
        (w[o].q = w[o].q || []).push(arguments);
      };
    (js = d.createElement(s)), (fjs = d.getElementsByTagName(s)[0]);
    js.id = o;
    js.src = f;
    js.async = 1;
    js.onload = cb;
    fjs.parentNode.insertBefore(js, fjs);
  })(
    window,
    document,
    'script',
    'stv',
    'https://s3-us-west-2.amazonaws.com/st-scripts/storytap-videos-2.js'
  );
};
/* eslint-enable */
