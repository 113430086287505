import React, { useEffect } from 'react';
import storytapService from './StorytapService';
import { StorytapProps } from './models';

import './storytap.scss';

const Storytap = ({ id, lang, eid }: StorytapProps) => {
  useEffect(() => {
    storytapService.init({
      id,
      lang,
      eid,
    });

    return () => {
      storytapService.clearQueue();
      storytapService.removeScript();
    };
  }, [lang]);

  return (
    <div className="storytap" data-testid="storytap">
      <div id="stpvp-embed" className="stpvp-embed scroll top" />
    </div>
  );
};

export default Storytap;
