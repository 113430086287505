import React, { FC } from 'react';
import { ApplicationAreasType } from './models';
import ApplicationAreasItem from './ApplicationAreasItem';

import './ApplicationAreas.scss';

const ApplicationAreas: FC<ApplicationAreasType> = ({
  applicationAreaNodes: { applicationAreasTitle, applicationAreasItems },
}) => (
  <div className="application-areas">
    <h2 className="application-areas__title">{applicationAreasTitle}</h2>
    <div className="application-areas__items" data-testid="applications-list-cards">
      {applicationAreasItems?.map((applicationAreasItem) => (
        <ApplicationAreasItem
          key={applicationAreasItem.applicationAreasItemTitle}
          applicationAreasItemTitle={applicationAreasItem.applicationAreasItemTitle}
          applicationAreasItemImage={applicationAreasItem.applicationAreasItemImage}
        />
      ))}
    </div>
  </div>
);

export default ApplicationAreas;
