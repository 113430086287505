import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { GlassMagnifier } from 'react-image-magnifiers';
import classNames from 'classnames';

import PlainImage from 'common/Image/PlainImage';
import Image from 'common/Image';
import ProductDetailsButton from 'common/ProductDetailsButton/ProductDetailsButton';
import ProductImageGallery from 'common/ProductImageGallery/ProductImageGallery';
import BazaarVoice from 'common/BazaarVoice';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';

import ProductPriceSpider from 'components/Products/ProductPriceSpider';
import ScreenRecognitionContext from 'utils/context/screenRecognitionContext';
import { extractUrlFromMultiPicker } from 'utils/umbracoControlsUtils';
import { isBrowser } from 'utils/browser';

import { ProductDetailsProps } from './models';
import { ESCAPE_KEY } from './constants';

import './ProductDetails.scss';

const ProductDetails: FC<ProductDetailsProps> = ({
  productTitle,
  productTitleLong,
  localProductImage,
  localProductImagePackFront,
  localProductImageApplication,
  productImage,
  productImagePackFront,
  productImageApplication,
  productFeature1,
  productFeature2,
  productFeature3,
  productFeature4,
  productFeature5,
  productFeature6,
  productFeature7,
  productFeature8,
  productDescriptionLong,
  productDisclaimers,
  productEAN,
  productTitleWithSize,
  closeModalAriaLabel,
  productContent,
  dropdownButton,
  shops,
  featuresTitle,
  currentCategory,
  showMenBannerCategory,
}) => {
  const { variants } = (productContent && productContent.length && productContent[0]) || {};

  const screenRecognitionContext = useContext(ScreenRecognitionContext);
  const [isSupportingWebp, setIsSupportingWebp] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [mainImage, setMainImage] = useState(productImage);
  const [mainLocalImage, setMainLocalImage] = useState(
    localProductImage || localProductImageApplication || localProductImagePackFront
  );

  const handleModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  const handleEscFunction = useCallback((event: KeyboardEvent) => {
    if (event.key === ESCAPE_KEY) {
      setIsModalVisible(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleEscFunction);
    setIsSupportingWebp(
      document.createElement('canvas').toDataURL('image/webp')?.indexOf('data:image/webp') === 0
    );

    return () => {
      document.removeEventListener('keydown', handleEscFunction);
    };
  }, []);

  const featuresArray: string[] = [
    productFeature1,
    productFeature2,
    productFeature3,
    productFeature4,
    productFeature5,
    productFeature6,
    productFeature7,
    productFeature8,
  ];

  const imagesArray = [
    {
      image: productImage,
      localImage: localProductImage,
    },
    {
      image: productImageApplication,
      localImage: localProductImageApplication,
    },
    {
      image: productImagePackFront,
      localImage: localProductImagePackFront,
    },
  ].filter((item, pos, arr) => item.image && (!pos || item !== arr[pos - 1]));

  const glassMagnifierProps = {
    imageAlt: productTitle || 'Veet product',
    square: true,
    className: 'product-details-page__image--zoom',
    magnifierBorderColor: 'rgb(204, 204, 204)',
    magnifierBorderSize: 1,
    magnifierSize: '150px',
  };

  return (
    <div className="product-details-page" data-testid="products-details">
      <div className="product-details-page__image--wrapper">
        <div className="product-details-page__image" data-testid="product-details-image">
          <div role="button" tabIndex={0} onClick={handleModal}>
            {screenRecognitionContext.isMobile ? (
              mainLocalImage ? (
                <Image localImage={mainLocalImage} alt={productTitle!} />
              ) : mainImage ? (
                <PlainImage lazyLoad src={mainImage} alt={productTitle!} />
              ) : null
            ) : mainLocalImage ? (
              <GlassMagnifier
                {...glassMagnifierProps}
                imageSrc={
                  isSupportingWebp
                    ? mainLocalImage.childImageSharp.fluid.srcWebp
                    : mainLocalImage.childImageSharp.fluid.src
                }
              />
            ) : mainImage ? (
              <GlassMagnifier {...glassMagnifierProps} imageSrc={mainImage} />
            ) : null}
          </div>

          {imagesArray[1] ? (
            <ProductImageGallery
              imagesArray={imagesArray}
              productTitle={productTitle}
              mainImage={mainImage}
              setMainImage={setMainImage}
              setMainLocalImage={setMainLocalImage}
            />
          ) : null}
        </div>
      </div>
      {isModalVisible ? (
        <div className="product-modal">
          <div
            className="product-modal__overlay"
            onClick={handleModal}
            role="button"
            aria-label={closeModalAriaLabel}
            tabIndex={0}
          />
          <div className="product-modal__body">
            <button
              aria-label={closeModalAriaLabel}
              className="product-modal__close"
              onClick={handleModal}
              type="button"
            />
            {mainLocalImage ? <Image localImage={mainLocalImage} alt={productTitle!} /> : null}
            {!mainLocalImage && productImage ? (
              <PlainImage lazyLoad src={productImage} alt={productTitle!} />
            ) : null}
          </div>
        </div>
      ) : null}
      <div className="product-details-page__content--wrapper">
        <div className="product-details-page__content">
          <h2 className="product-details-page__title">
            {productTitleLong || productTitle || productTitleWithSize}
          </h2>
          <BazaarVoice eanCode={productEAN} showOnlyRating />
          <div className="product-details-page__btn" data-testid="product-card-buttons">
            {dropdownButton ? (
              <ProductDetailsButton
                shops={shops}
                dropdownButton={dropdownButton}
                isModalVisible={isModalVisible}
              />
            ) : (
              <ProductPriceSpider {...{ productEAN, currentCategory }} />
            )}
          </div>
          {variants ? (
            <div className="product-details-page__variants">
              {variants.map(({ variantLink, variantText }) => (
                <Link
                  to={extractUrlFromMultiPicker(variantLink)}
                  key={variantText}
                  className="product-details-page__variants-size"
                  activeClassName="product-details-page__variants-size--active"
                >
                  {variantText}
                </Link>
              ))}
            </div>
          ) : null}
          <div className="product-details-page__description">
            <DangerouslySetInnerHtml html={productDescriptionLong} />
          </div>
          <div className="product-details-page__features">
            {featuresTitle ? (
              <h3 className="product-details-page__features-title">{featuresTitle}</h3>
            ) : null}
            <ul className="product-details-page__features-items">
              {featuresArray.map((item) =>
                item.length > 0 ? (
                  <li
                    className={classNames('product-details-page__features-item', {
                      'men--banner':
                        showMenBannerCategory === currentCategory ||
                        (isBrowser() && window.location.pathname.includes('/products-list/men/')),
                    })}
                    key={item}
                  >
                    <DangerouslySetInnerHtml html={item} />
                  </li>
                ) : null
              )}
            </ul>
          </div>
          {productDisclaimers ? (
            <DangerouslySetInnerHtml
              html={productDisclaimers}
              className="product-details-page__disclaimers"
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
