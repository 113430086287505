const mapProductToBvSchema = (product) => {
  const productImageURL = product.productImage;
  const pageUrl = `${window.location.protocol}//${window.location.host}`;

  return {
    brandName: process.env.GATSBY_BRAND_NAME,
    productId: product.id,
    productName: product.title,
    productPageURL: `${pageUrl}${product.url}`,
    categoryPath: product.category,
    ...(product.productImage && { productImageURL }),
  };
};

const trackCatalogUpdate = (products, lang) => {
  const catalogProducts = products.map(mapProductToBvSchema);

  window.bvDCC = {
    catalogData: {
      locale: lang,
      catalogProducts,
    },
  };

  catalogProducts.forEach((product) => {
    window.BV.pixel.trackEvent('CatalogUpdate', {
      type: 'Product',
      locale: window.bvDCC.catalogData.locale,
      catalogProducts: [product],
    });
  });
};

export default trackCatalogUpdate;
