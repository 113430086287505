import React, { FC } from 'react';
import { HowApplyStepsType } from './models';
import HowApplyStep from './HowApplyStep';

import './HowApplySteps.scss';

const HowApplySteps: FC<HowApplyStepsType> = ({
  howApplyStepsNodes: { applyTitle, applyList },
}) => (
  <div className="how-apply">
    <h2 className="how-apply__title">{applyTitle}</h2>
    {applyList && applyList.length ? (
      <ol className="how-apply__items" data-testid="how-apply-cards">
        {applyList.map((applyListItem) => (
          <HowApplyStep
            key={applyListItem.applyItemTitle}
            applyItemTitle={applyListItem.applyItemTitle}
            applyItemImage={applyListItem.applyItemImage}
          />
        ))}
      </ol>
    ) : null}
  </div>
);

export default HowApplySteps;
